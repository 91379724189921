jQuery(document).ready(function () {

  if (window.matchMedia('(min-width: 1000px)').matches) {
    if ($('#fullpage').length) {
      $('#fullpage').fullpage({
        // licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        autoScrolling: true,
        scrollHorizontally: true,
        anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7'],
        navigation: false,
        normalScrollElements: '.modal-wrap',
        afterLoad: function (e, d, direction) {
          $('.js-fullpage-next').attr('href', '#page' + (d.index + 2));
          if (d.index > 1) {
            $('.js-main-bg-1').hide();
          }
          /* $('.js-nav-top').each(function (a) {
            $(this).removeClass("active");
            if (a === d.index && d.index != 2 && d.index != 1) {
              $(this).addClass("active");
            }
          }) */
        },
        onLeave: function (e, d, direction) {
          $('.js-fullpage-next').attr('href', '#page' + (d.index + 1))
          if (d.index > 0) {
            $('.js-main-disc').css('bottom', '50%');
          } else {
            $('.js-main-disc').css('bottom', '-50%');
          }
          if (d.index > 0) {
            $('.js-music-main').css('transform', 'translateY(-100%)');
          } else {
            $('.js-music-main').css('transform', 'translateY(0)');
          }
          if (d.index > 1) {
            $.parallaxify('destroy');
            $('.js-main-disc').css('bottom', '110%');
          } else {
            $('.js-main-bg-1').show();
            parall();
          }
          if (d.index == 4) {
            $('.js-main-bg-2').addClass('bg-show');
            $('body').addClass('footer-reach');
            $('.footer-next').attr('tabindex', '-1');
          } else {
            $('.js-main-bg-2').removeClass('bg-show');
            $('body').removeClass('footer-reach');
            $('.footer-next').attr('tabindex', '0');
          }
          if (d.index == 2) {
            $('body').addClass('remove-nav');
            $('body').addClass('change-color');
          } else {
            $('body').removeClass('remove-nav');
            $('body').removeClass('change-color');
          }
        }

      });
    }
  }


  function parall() {
    $.parallaxify({
      alphaFilter: 1,
      motionType: 'performance',
      mouseMotionType: 'performance',
      positionProperty: 'transform',
      alphaPosition: .1,
    });
  }

  if (window.matchMedia("(min-width: 1000px)").matches) {
    parall();
  }

  /*if (isInPage(document.querySelector('.icon-video-play'))) {

    var iconVideo = document.querySelector('.icon-video-play'),
      iconVideoClose = document.querySelector('.js-video-close');
    iconVideo.addEventListener('click', function (e) {
      var elm = this,
        fv = document.querySelector('.js-video-full');
      elm.style.transform = "scale(120)";
      elm.querySelector('svg').style.fill = "#fff";
      setTimeout(function () {
        elm.style.display = "none";
      }, 400);
      setTimeout(function () {
        fv.querySelector('video').play();
        fv.style.opacity = 1;
        fv.style.visibility = "visible";
      }, 50);
    });

    if (iconVideoClose) {
      function videoShow(el) {
        var fv = el;
        iconVideo.style.display = "block";
        iconVideo.style.transform = "scale(1)";
        iconVideo.querySelector('svg').style.fill = "#fff"
        fv.querySelector('video').pause();
        fv.style.opacity = 0;
        fv.style.visibility = "hidden";
      }

      iconVideoClose.addEventListener('click', function (e) {
        videoShow(document.querySelector('.js-video-full'));
      });
      document.addEventListener('keyup', function (e) {
        if (e.keyCode === 27) {
          videoShow(document.querySelector('.js-video-full'));
        }
      });
    }
  }*/

  if ($('.js-modal').length) {
    for (var i = document.querySelectorAll('.js-modal').length - 1; i >= 0; i--) {
      miniModal(document.querySelectorAll('.js-modal')[i]);
    }
    for (var i = document.querySelectorAll('.js-modal-close').length - 1; i >= 0; i--) {
      miniModal(document.querySelectorAll('.js-modal-close')[i]);
    }
  }
  if ($('.js-modal-min').length) {
    for (var i = document.querySelectorAll('.js-modal-min').length - 1; i >= 0; i--) {
      miniModal(document.querySelectorAll('.js-modal-min')[i], true);
    }
    for (var i = document.querySelectorAll('.js-modal-min-close').length - 1; i >= 0; i--) {
      miniModal(document.querySelectorAll('.js-modal-min-close')[i], true);
    }
  }

  if ($('.js-beats-hand').length) {
    wiyzjalka($('.js-beats-hand'), '.js-beats-parent', '.js-beats-box', "normal", false, 0);
  }
  if ($('.js-rent-hand').length) {
    wiyzjalka($('.js-rent-hand'), '.js-rent-parent', '.js-rent-box', "normal", false, 0);
  }
  wiyzjalka($('.js-nav-hand'), '.js-nav-parent', '.js-nav-box', "normal", false, 0);

  $(window).on('scroll', function () {

    let ps = $(this).scrollTop()

    if ($('.js-fixed-head').length) {
      if (ps >= 400) {
        $('.header').addClass('temp-fixed')
      } else {
        $('.header').removeClass('temp-fixed')
      }
      if (ps >= $('.js-fixed-head').offset().top) {
        $('.header').addClass('fixed-head');
      } else {
        $('.header').removeClass('fixed-head');
      }
    }
    if ($('.player-beats').length) {

      if (ps >= 72) {
        $('.player-beats').addClass("active");
        if ($(document).height() - $(window).height() < (ps + 70)) {
          $('.player-beats').removeClass("active");
        } else {
          $('.player-beats').addClass("active");
        }
      } else {
        $('.player-beats').removeClass("active");
      }
      // console.log( ps);
    }
  })
  $(window).trigger('scroll');

  /* if ($('.js-btn-remove').length) {
    $('.js-btn-remove').on("click",function(){
      $(this).parents('.js-remove-tr').remove();
    })
  } */

  if ($('.js-scroll-down').length) {
    $('.js-scroll-down').each(function () {
      $(this).click(function () {

        var id = $(this).attr("href"),
          paddingTop = $(this).attr("data-padding");

        if (typeof paddingTop === typeof undefined || paddingTop === false || paddingTop === NaN) {
          paddingTop = 125;
        }

        $('body, html').animate({scrollTop: $(id).offset().top - (+paddingTop)}, 'slow');

        return false;

      })
    });
  }

  /* PLAYER TOGGLE HIDE/SHOW */
  if ($('.js-btn-player').length) {
    $('.js-btn-player').each(function () {
      $(this).on('click', function () {
        $(this).toggleClass("active");
        $('#mainwrap').toggle();
        $('#npTrackID').toggle();
      })
    })
  }

  function beatPlayer(tracks) {
    // jQuery(function ($) {
    'use strict'
    if ($(".player-beats").length) {
      var supportsAudio = !!document.createElement('audio').canPlayType;
      if (supportsAudio) {
        // initialize plyr
        var player = new Plyr('#audio1', {
          controls: [
            'restart',
            'play',
            'progress',
            'current-time',
            'duration',
            'mute',
            'volume',
          ],
          global: true
        });
        // initialize playlist and controls

        var index = 0,
          playing = false,
          extension = '',
          trackCount = tracks.length,
          npAction = $('#npAction'),
          npTitle = $('#npTitle'),
          npShare = $('#shareTrack'),
          npTrackID = $('#npTrackID'),
          audio = $('#audio1').on('play', function () {
            playing = true;
            // npAction.text('Now Playing...');
          }).on('pause', function () {
            playing = false;
            // npAction.text('Paused...');
          }).on('ended', function () {
            // npAction.text('Paused...');
            if ((index + 1) < trackCount) {
              index++;
              loadTrack(index);
              loadImg(index);
              audio.play();
            } else {
              audio.pause();
              index = 0;
              loadTrack(index);
              loadImg(index);
            }
          }).get(0),
          btnPrev = $('#btnPrev').on('click', function () {
            if ((index - 1) > -1) {
              index--;
              loadTrack(index);
              loadImg(index);
              if (playing) {
                audio.play();
              }
              $('.js-play-track-list').removeClass('active');
              $('.js-play-track-list').eq(index).addClass("active");
            } else {
              audio.pause();
              index = 0;
              loadTrack(index);
              loadImg(index);
              $('.js-play-track-list').removeClass('active');
              $('.js-play-track-list').eq(index).addClass("active");
            }
          }),
          btnNext = $('#btnNext').on('click', function () {
            if ((index + 1) < trackCount) {
              index++;
              loadTrack(index);
              loadImg(index);
              if (playing) {
                audio.play();
              }
              $('.js-play-track-list').removeClass('active');
              $('.js-play-track-list').eq(index).addClass("active");
            } else {
              audio.pause();
              index = 0;
              loadTrack(index);
              loadImg(index);
              $('.js-play-track-list').removeClass('active');
              $('.js-play-track-list').eq(index).addClass("active");
            }
          }),
          playTrack = $('.js-play-track,.js-play-track-list').each(function () {
            $(this).on('click', function (e) {
              $('.js-play-track-list').removeClass('active');
              $(this).addClass("active");
              let id = $(this).attr('data-track');
              var numTrack = tracks.findIndex(x => x["ID"] === id);
              // console.log(numTrack);
              playTrack(numTrack);
              loadImg(numTrack);
            })
          }),
          loadTrack = function (id) {
            // console.log(index, trackCount);
            // console.log(id);
            // $('.plSel').removeClass('plSel');
            // $('#plList li:eq(' + id + ')').addClass('plSel');
            npTitle.html(
              "<b class=\"track-art\"><span>" + tracks[id].artist + "</span></b><b class=\"track-name\"><span>" + tracks[id].name + "</span></b>"
            );
            npShare.find('a').each(function () {
              $(this).attr('href', tracks[id][$(this).attr('data-share')])
            });
            npTrackID.attr('data-product-id', tracks[id]["ID"]);
            index = id;
            audio.src = tracks[id].file + extension;
          },
          loadImg = function (id) {
            npAction.html('<img src="' + tracks[id].file + ".jpg" + '">');
          },
          playTrack = function (id) {
            loadTrack(id);
            audio.play();
          };
        extension = audio.canPlayType('audio/mpeg') ? '.mp3' : audio.canPlayType('audio/ogg') ? '.ogg' : '';
        // console.log(index);
        loadTrack(index);
      } else {
        // no audio support
        $('.column').addClass('hidden');
        var noSupport = $('#audio1').text();
        $('.container').append('<p class="no-support">' + noSupport + '</p>');
      }
    }
  };
  // beatPlayer(tracks);

  if ($(".js-mobile-swipe").length) {
    $(".js-mobile-swipe").carousel({
      swipe: 50
    });
    if ((window.matchMedia('(max-width: 1000px)').matches)) {
      $(".js-mobile-swipe").carousel({
        interval: 5000
      });
      $(".js-mobile-swipe").on('slide.bs.carousel', function (e) {
        $(e.relatedTarget).parent().css('height', $(e.relatedTarget).outerHeight())
      })
    }
  }

  if ($('.js-lazy-tab').length) {
    $('.js-lazy-tab').parent().find('.tab-pane.active').find('img[data-src]').each(function () {
      $(this).attr('src', $(this).attr('data-src'));
    })
    $('.js-lazy-tab *[role=presentation] a').on('click', function (e) {
      e.preventDefault();
      $(this).on('shown.bs.tab', function (e) {
        var target = $(e.target).attr("href");
        $(target).parent().find('img[data-src]').each(function () {
          $(this).attr('src', $(this).attr('data-src'));
        })
      });
    })
  }

  if ($('.js-file-form').length) {

    $('.js-file-form').each(function () {
      $(this).change(function () {
        if ($(this).val().length > 0) {
          $(this).parent().find('input[type=text]').val('"' + $(this).val().slice($(this).val().lastIndexOf("\\") + 1) + '"');
        }
      });
    });
  }

  if ($('.owl-carousel').length) {

    $('.owl-carousel').each(function () {
      owlA11ySet($("#" + $(this).attr("id")), true, 5000);

      var owl = $(this).owlCarousel({
        loop: ($(this).attr('data-loop')) ? false : true,
        margin: ($(this).attr('data-margin')) ? +$(this).attr('data-margin') : 40,
        autoWidth: true,
        dragEndSpeed: 1000,
        autoplay: ($(this).attr('data-autoplay')) ? true : false,
        dots: false,
        items: 1,
      });
      owl.one('drag.owl.carousel', function () {
        owl.find('.owl-stage-outer').css('overflow', 'visible');
      });
    });

  }

  $('.js-same-page').each(function () {
    $(this).click(function () {
      let cls = $(this);
      setTimeout(function () {
        if ($('.js-hash-tabs[href="' + window.location.hash + '"]').length) {
          $('.js-hash-tabs[href="' + window.location.hash + '"]').click();
        } else {
          $('body, html').animate({scrollTop: $('.js-hash[data-scroll="' + window.location.hash + '"]').offset().top}, 'slow');
          cls.parents('#menu').find('.js-modal-close').click();
        }
      }, 100);
    });
  });
  if (window.location.hash) {
    var hash = window.location.hash;
    if ($('.js-hash[data-scroll="' + hash + '"]').length) {
      setTimeout(function () {
        $('body, html').animate({scrollTop: $('.js-hash[data-scroll="' + hash + '"]').offset().top}, 'slow');
      }, 500)
    }
    if ($('.js-hash-tabs[href="' + hash + '"]').length) {
      setTimeout(function () {
        $('.js-hash-tabs[href="' + hash + '"]').click();
      }, 100)
    }
  }

  /* if ($('.js-add-cart').length) {
    $('.js-add-cart').on('click',function(){
      $('.js-msg-window').addClass('active');
      $('.js-cart-num').parent().addClass('active');
      if ($('.js-cart-num').text().length) {
        $('.js-cart-num').text(+$('.js-cart-num').text()+1)
      } else {
        $('.js-cart-num').text("1")
      }
      setTimeout(function(){
        $('.js-msg-window').removeClass('active');
        $('.js-cart-num').parent().removeClass('active');
      },1000)
    })
  } */

  /*if($('img.stripe-icon').length) {
    consloe.log($('img.stripe-icon'));
    $($('img.stripe-icon')).each(function(){
      $(this).on('click',function(){
        $(this).parent().next().show('normal');
      })
    })
  }*/

  if($('.js-music-freq').length) {
    $('.js-music-freq').each(function(){
      for (let i=1; i<=10; i++) {
        $(this).append('<div class="freq h-5"></div><div class="freq h-5"></div><div class="freq h-5"></div><div class="freq h-10"></div><div class="freq h-40"></div><div class="freq h-60"></div><div class="freq h-70"></div><div class="freq h-100"></div><div class="freq h-40"></div><div class="freq h-20"></div><div class="freq h-30"></div><div class="freq h-10"></div><div class="freq h-5"></div><div class="freq h-5"></div><div class="freq h-5"></div><div class="freq h-10"></div><div class="freq h-20"></div><div class="freq h-60"></div><div class="freq h-50"></div><div class="freq h-20"></div><div class="freq h-10"></div><div class="freq h-5"></div><div class="freq h-10"></div><div class="freq h-50"></div><div class="freq h-80"></div><div class="freq h-100"></div><div class="freq h-50"></div><div class="freq h-80"></div><div class="freq h-40"></div><div class="freq h-10"></div><div class="freq h-5"></div><div class="freq h-20"></div><div class="freq h-30"></div>');
      }
    })
  }

})

if ($('.produce-list').length) {
  // $('.produce-list').css("opacity","0");
  var masonryOptions = {
    itemSelector: '.produce-list__item'
  };
  var msnry = $('.produce-list').masonry(masonryOptions);
}
$(window).on("load", function () {
  // $('.produce-list').css("opacity","1");
  $('body').addClass('loaded')
  if ($('.produce-list').length) {
    msnry.masonry(masonryOptions);
  }
});